import React, { FC, useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useAuthProviderLogin, useSUBContext } from "@reasongcp/react-fire-sub";
import { AuthProvider, OAuthProvider } from "firebase/auth";
import { ProviderDetails } from "../../../types";

interface AuthButtonProps {
  provider: AuthProvider;
  details: ProviderDetails;
}

const AuthButton: FC<AuthButtonProps> = ({ provider, details }) => {
  const { auth } = useSUBContext();
  // Because firebase auth emulator can't handle the redirect with `loalhost`
  // it appears...
  const redirect = !window.location.hostname.match(/localhost/);
  const handleLogin = useAuthProviderLogin({ redirect, provider });
  const handleClick = useCallback(() => {
    if (details.identityPlatformTenantId && auth) {
      auth.tenantId = details.identityPlatformTenantId;
    }

    handleLogin();
  }, [details, handleLogin, auth]);

  return <Button onClick={handleClick}>Login with {details.name}</Button>;
};

const ProviderOption: FC<Pick<AuthButtonProps, "details">> = ({ details }) => {
  const provider = useMemo(() => {
    if (details.providerType === "OAuthProvider") {
      const authProvider = new OAuthProvider(details.config || "");
      if (details.tenantId) {
        authProvider.setCustomParameters({ tenant: details.tenantId });
      }

      return authProvider;
    }
  }, [details]);

  if (!provider) return null;
  return (
    <AuthButton
      details={details}
      provider={provider}
    />
  );
};

export default ProviderOption;
