import React, { FC } from "react";
import styled from "styled-components";
import { Nav, NavLinkProps } from "react-bootstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOrganizationMembers from "../../../hooks/useOrganizationMembers";
import { Link, useParams } from "react-router-dom";

interface CustomLinkProps extends NavLinkProps {
  to: string;
  active?: boolean;
}

const CustomLink: FC<CustomLinkProps> = ({ active, ...linkProps }) => {
  return <Nav.Link {...linkProps} />;
};

const NavLink: FC<CustomLinkProps> = styled(CustomLink)`
  color: #6c757d;
  ${({ active }) => active && "color: black;"}

  &:hover {
    color: black !important;
  }
`;

interface OrganizationUserProps {
  uid: string;
  email: string;
  displayName?: string;
}

const OrganizationUser: FC<OrganizationUserProps> = ({
  uid,
  email,
  displayName,
}) => {
  const { uid: currentUid } = useParams();
  const isActive = uid === currentUid;
  const userLink = `/users/${uid}`;
  const activeIcon = (
    <>
      <FontAwesomeIcon
        icon={faChevronRight}
        aria-hidden="true"
      />
    </>
  );
  return (
    <NavLink
      active={isActive}
      as={Link}
      to={userLink}
      className="px-3 py-2 text-decoration-none d-flex justify-content-between"
    >
      <span>{displayName || email}</span>
      <span className="align-middle">{isActive ? activeIcon : ""}</span>
    </NavLink>
  );
};

export interface OrganizationUsersProps {
  filter?: string;
}

const checkMatch = (filter: string) => (value?: null | string) => {
  return Boolean(value?.toLowerCase().match(filter.toLowerCase()));
};

const OrganizationUsers: FC<OrganizationUsersProps> = ({ filter }) => {
  const members = useOrganizationMembers().filter((member) => {
    if (!filter) return true;
    const checker = checkMatch(filter);
    return checker(member.email) || checker(member.displayName);
  });

  if (!members.length) return null;

  return (
    <>
      {members.map((member) => {
        return (
          <OrganizationUser
            key={member.uid}
            uid={member.uid}
            email={member.email}
            displayName={member.displayName}
          />
        );
      })}
    </>
  );
};

export default OrganizationUsers;
