import { useLazyQuery } from "@apollo/client";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import { useEffect } from "react";

import config from "../config";
import {
  GetJurisdiction,
  GetOrganization,
  GET_ORGANIZATION,
  GET_JURISDICTION,
} from "../queries/organizations";

const setOrganization = (
  organization: GetOrganization["organization"],
  jurisdictionName: string = "",
  jurisdictionType: string = "",
  stateAbbr: string = "",
) => ({
  ...organization,
  jurisdictionName,
  jurisdictionType,
  stateAbbr,
});

const getOrganization = (
  organization: GetOrganization["organization"],
  jurisdiction: GetJurisdiction["jurisdiction"],
) => {
  const {
    stateAbbr,
    jurisdictionType,
    name: jurisdictionName,
  } = jurisdiction || {
    stateAbbr: "",
    jurisdictionType: "",
    name: "",
  };

  return setOrganization(
    organization,
    jurisdictionName,
    jurisdictionType,
    stateAbbr,
  );
};

const useOrganization = (orgId?: string) => {
  const user = useUserDoc();
  const taxbaseClient = useApolloClientWithAuth(config.taxbaseApi);
  const [jurQuery, { data: jurData }] = useLazyQuery<GetJurisdiction>(
    GET_JURISDICTION,
    {
      client: taxbaseClient,
      nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      variables: { uid: orgId || user?.currentOrganization },
    },
  );
  const [orgQuery, { data: orgData }] = useLazyQuery<GetOrganization>(
    GET_ORGANIZATION,
    {
      nextFetchPolicy: "cache-first",
      fetchPolicy: "cache-and-network",
      variables: { uid: orgId || user?.currentOrganization },
    },
  );

  useEffect(() => {
    if (orgId || user?.currentOrganization) {
      orgQuery();
      jurQuery();
    }
  }, [orgId, user, orgQuery, jurQuery]);

  return getOrganization(orgData?.organization, jurData?.jurisdiction);
};

export default useOrganization;
