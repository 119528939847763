import React, { FC } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import tsQuill from "../../../images/taxscribe-quill-light.svg";
import config from "../../../config";
import useNewRedirectedUserDoc from "../../../hooks/useNewRedirectedUserDoc";

const Welcome: FC = () => {
  useNewRedirectedUserDoc();

  const productItems = [
    {
      title: "TSG",
      subTitle: "TaxScribe Government",
      description: `
        TaxScribe Government expedites filing of tax forms by providing an online
        portal connecting the taxpayer to you. Online filing provides real time
        status and an audit trail of filings and a message system allows you to
        reach taxpayers directly.
      `,
      bgClass: "ts-bpp-bg-blue",
      link: config.bppUrl,
      tooltip: "Continue",
    },
  ];

  return (
    <main className="w-100">
      <Navbar
        id="header-nav"
        className="bg-white sticky-top navbar navbar-expand-lg navbar-light"
      >
        <Container
          fluid
          className="border-bottom px-0 py-3 mx-3 "
        >
          <span className="text-uppercase ls-1 navbar-brand">
            TaxScribe Products
          </span>
        </Container>
      </Navbar>
      <Container
        fluid
        className="p-3"
      >
        <Row className="g-4 row-cols-1 row-cols-md-3">
          {productItems.map(
            ({ title, subTitle, bgClass, description, link, tooltip }, idx) => (
              <Col
                key={idx}
                xl={3}
                lg={4}
                md={6}
              >
                <Card
                  className={`rounded-4 ${bgClass} border-0 text-white h-100`}
                >
                  <Card.Body className="d-flex flex-column">
                    <Card.Title as="h3">
                      <img
                        alt="TaxScribe Quill Icon"
                        src={tsQuill}
                        width="35"
                        className="me-2"
                      />
                      {title}
                    </Card.Title>
                    <div>
                      <Card.Subtitle
                        as="h6"
                        className="mb-2 fw-bold"
                      >
                        {subTitle}
                      </Card.Subtitle>
                      <Card.Text>{description}</Card.Text>
                    </div>
                    <div className="text-center mt-auto">
                      <hr />
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{tooltip}</Tooltip>}
                      >
                        <span>
                          <Button
                            variant="outline-light"
                            size="lg"
                            as="a"
                            href={link}
                          >
                            Continue
                          </Button>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ),
          )}
        </Row>
      </Container>
    </main>
  );
};

export default Welcome;
