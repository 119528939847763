import { FC } from "react";
import { Form } from "react-bootstrap";

interface FormikErrorHandlerProps {
  error?: any;
}

const FormikErrorHandler: FC<FormikErrorHandlerProps> = ({ error }) => {
  if (!error) return null;

  return <Form.Label className="small text-danger">{error}</Form.Label>;
};

export default FormikErrorHandler;
