import useOrganization from "./useOrganization";

// Declared outside of hook for stable reference.
const defaultMembers: Record<string, any>[] = [];

const useOrganizationMembers = (orgId?: string) => {
  const organization = useOrganization(orgId);
  return organization?.members || defaultMembers;
};

export default useOrganizationMembers;
