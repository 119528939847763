import { FC, useCallback, useMemo } from "react";
import Select, { MultiValue } from "react-select";

interface SelectOption<T> {
  value: T;
  label: string;
}

interface FormikSelectProps {
  formik: Record<string, any>;
  fieldName: string;
  options: SelectOption<string>[];
}

const FormikSelect: FC<FormikSelectProps> = ({
  formik,
  fieldName,
  options,
}) => {
  const setFieldValue = formik.setFieldValue;
  const setValue = useCallback(
    (values: MultiValue<SelectOption<string>>) => {
      const newValues = values.map(({ value }) => value);
      setFieldValue(fieldName, newValues);
    },
    [fieldName, setFieldValue],
  );

  const value = useMemo(() => {
    return options.filter((opt) => formik.values.roles.includes(opt.value));
  }, [formik.values, options]);

  return (
    <Select
      isMulti
      value={value}
      name={fieldName}
      options={options}
      onChange={(values) => setValue(values)}
    />
  );
};

export default FormikSelect;
