import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

import config from "../config";
import { ProviderDetails } from "../types";

const GET_AUTH_PROVIDERS = gql`
  query Jurisdiction(
    $stateAbbr: String
    $jurisdictionName: String
    $jurisdictionType: String
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      id
      authProviders {
        name
        config
        tenantId
        providerType
        identityPlatformTenantId
      }
      uid
      name
    }
  }
`;

interface GetAuthProvidersResults {
  jurisdiction: {
    id: number;
    uid: string;
    name: string;
    authProviders: ProviderDetails[];
  };
}

const useGetAuthProviders = () => {
  const client = useApolloClientWithAuth(config.taxbaseApi);
  const [searchParams] = useSearchParams();
  const { stateAbbr, jurisdictionType, jurisdictionSlug } = useParams();
  const [query, results] = useLazyQuery<GetAuthProvidersResults>(
    GET_AUTH_PROVIDERS,
    { client },
  );

  useEffect(() => {
    const jurStateAbbr = stateAbbr || searchParams.get("stateAbbr");
    const jurType = jurisdictionType || searchParams.get("jurisdictionType");
    const jurName = jurisdictionSlug || searchParams.get("jurisdictionName");
    if (!jurStateAbbr || !jurType || !jurName) return;
    const variables = {
      stateAbbr: jurStateAbbr,
      jurisdictionType: jurType,
      jurisdictionName: jurName.replace(/-/g, " "),
    };

    query({ variables });
  }, [
    query,
    stateAbbr,
    jurisdictionType,
    jurisdictionSlug,
    searchParams,
  ]);
  return results;
};

export default useGetAuthProviders;
