import { useMutation } from "@apollo/client";
import { FC, useCallback } from "react";
import { useFormik } from "formik";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import styled from "styled-components";
import * as Yup from "yup";

import { CreateUser, CREATE_USER } from "../../../queries/users";
import { GET_ORGANIZATION } from "../../../queries/organizations";
import { useNavigate } from "react-router";
import { useAppToastContext } from "@taxscribe/ui";
import { RoleList, defaultRole } from "./Roles";
import FormikSelect from "../../shared/FormikSelect";
import FormikErrorHandler from "../../shared/FormikErrorHandler";

const FormButton = styled(Button)`
  width: 220px;
`;

const initialValues = {
  email: "",
  displayName: "",
  roles: [defaultRole],
};

const requiredText = "* Required";
const stringRequired = Yup.string().required(requiredText);
const NewUserSchema = Yup.object().shape({
  displayName: stringRequired,
  email: stringRequired.email(),
  roles: Yup.array().min(1, requiredText).required(requiredText),
});

const NewUserForm: FC = () => {
  const navigate = useNavigate();
  const user = useUserDoc();
  const { addToastMessage } = useAppToastContext();
  const [mutate, { error }] = useMutation<CreateUser>(CREATE_USER);
  const formik = useFormik({
    initialValues,
    validationSchema: NewUserSchema,
    onSubmit: async (values) => {
      try {
        await mutate({
          variables: {
            ...values,
            organizationUid: user?.currentOrganization,
          },
          refetchQueries: [GET_ORGANIZATION],
        });
        formik.resetForm();
      } catch (e) {
        console.error(error);
        addToastMessage({
          type: "danger",
          header: "Error Creating User",
          body: error?.message || "Unable to create new user",
        });
      }
    },
  });

  const closeForm = useCallback(() => {
    navigate("/users");
  }, [navigate]);

  return (
    <Container
      fluid
      className="px-0"
    >
      <Row className="border-bottom">
        <Col className="p-3 p-md-5">
          <h1 className="h2 fw-bold">New User</h1>
        </Col>
      </Row>
      <Row>
        <Col className="p-3 p-md-5">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col
                lg={3}
                md={4}
              >
                <h6 className="text-muted ts-1 fs-6 mb-4">
                  <small>EMPLOYEE DETAILS</small>
                </h6>
                <Form.Group className="mb-3">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayName"
                    placeholder="Jane Doe"
                    onChange={formik.handleChange}
                    value={formik.values.displayName}
                  />
                  <FormikErrorHandler error={formik.errors?.displayName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address *</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    value={formik.values.email}
                    placeholder="user@domain.com"
                    onChange={formik.handleChange}
                  />
                  <FormikErrorHandler error={formik.errors?.email} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Roles *</Form.Label>
                  <br />
                  <FormikSelect
                    formik={formik}
                    fieldName="roles"
                    options={RoleList}
                  />
                  <br />
                  <FormikErrorHandler error={formik.errors?.roles} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-md-4 mt-2">
              <Col>
                <FormButton
                  variant="dark"
                  type="submit"
                  className="me-4 px-5 mb-0 mb-lg-0 mb-sm-2"
                >
                  Save
                </FormButton>
                <FormButton
                  onClick={closeForm}
                  variant="secondary"
                  className="px-5"
                >
                  Cancel
                </FormButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NewUserForm;
