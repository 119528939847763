import { gql } from "@apollo/client";

export interface CreateUser {
  uid: string;
  email: string;
  displayName: string | null;
  phoneNumber: string | null;
}

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String
    $phoneNumber: String
    $displayName: String
    $organizationUid: String!
    $roles: [String]
  ) {
    createUser(
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      displayName: $displayName
      organizationUid: $organizationUid
      roles: $roles
    ) {
      uid
      email
      displayName
      phoneNumber
    }
  }
`;
