import { useCallback } from "react";
import { doc, runTransaction } from "firebase/firestore";
import { useSUBContext } from "@reasongcp/react-fire-sub";

const organizationsCollectionKey = "organizations";

interface UpdateUserArgs {
  userUid: string;
  orgUid: string;
  options: {
    roles?: string[];
    displayName?: string;
  };
}

export const useUpdateUser = () => {
  const { firestore } = useSUBContext();

  return useCallback(
    async ({ userUid, orgUid, options }: UpdateUserArgs) => {
      if (!firestore) return null;
      const { roles, displayName } = options;
      const orgRef = doc(firestore, organizationsCollectionKey, orgUid);
      const rolesValue = roles?.length ? roles : ["user"];
      const data = { roles: rolesValue, displayName };

      return await runTransaction(firestore, async (tx) => {
        const userInOrgRef = doc(orgRef, `/members/${userUid}`);
        tx.update(userInOrgRef, data);
      });
    },
    [firestore],
  );
};
