import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastComponentProps } from "@taxscribe/ui";
import React, { FC } from "react";
import { Toast } from "react-bootstrap";

const Danger: FC<ToastComponentProps> = ({ type, body, header, onClose }) => {
  return (
    <Toast
      onClose={onClose}
      bg={type}
    >
      <div className="d-flex flex-row-reverse justify-content-between">
        <Toast.Header className="bg-transparent align-self-start border-bottom-0">
          <span className="me-auto"></span>
        </Toast.Header>
        <Toast.Body className="fw-semibold p-3 d-flex align-items-center">
          <span>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size="2xl"
              className="me-3"
            />
          </span>
          <span>
            <strong className="fs-5">{header}</strong>
            <br />
            {body}
          </span>
        </Toast.Body>
      </div>
    </Toast>
  );
};

export default Danger;
