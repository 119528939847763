const userRole = { label: "User", value: "user" };
const adminRole = { label: "Administrator", value: "admin" };
const bulkRole = { label: "Bulk", value: "bulk" };
const revertApprovalRole = {
  label: "Revert Approval",
  value: "revertApproval",
};

export const RoleList = [userRole, adminRole, bulkRole, revertApprovalRole];

export const defaultRole = userRole;
