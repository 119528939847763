import { gql } from "@apollo/client";

export interface Member {
  uid: string;
  email: string;
  displayName: string;
  lastSignInTime: string;
  roles: string[];
}

export interface GetJurisdiction {
  jurisdiction?: {
    id: string;
    uid: string;
    stateAbbr: string;
    name: string;
    jurisdictionType: string;
  };
}

export interface GetOrganization {
  organization?: {
    uid: string;
    name: string;
    memberUids: string[];
    phone: string;
    city: string;
    streetAddress: string;
    secondaryAddress: string;
    zip: string;
    createdAt: string;
    members: Member[];
  };
}

export const GET_JURISDICTION = gql`
  query GetJurisdiction($uid: String!) {
    jurisdiction(uid: $uid) {
      id
      uid
      name
      stateAbbr
      jurisdictionType
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($uid: String!) {
    organization(uid: $uid) {
      uid
      name
      memberUids
      members {
        uid
        email
        displayName
        lastSignInTime
        roles
      }
      phone
      city
      streetAddress
      secondaryAddress
      zip
      createdAt
    }
  }
`;
