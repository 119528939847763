import React, { FC, useMemo, PropsWithChildren } from "react";
import { useAuth } from "@reasongcp/react-fire-sub";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";

/*
 * Initializes the client via a memoized hook so that we can use the current
 * user to inject Firebase auth credentials that are fresh into GQL requests.
 */
const useApolloClient = (uri: string) => {
  const user = useAuth();

  return useMemo(() => {
    const link = createHttpLink({
      uri: uri + "graphql",
    });

    const authLink = setContext(async (_, { headers }) => {
      const jwt = await user?.getIdToken();
      return {
        headers: {
          ...headers,
          authorization: jwt,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
    });
  }, [uri, user]);
};

interface AppApolloProviderProps extends PropsWithChildren {
  uri: string;
}

// NOTE: Candidate for extraction into @reasongcp/react-fire-sub.
const AppApolloProvider: FC<AppApolloProviderProps> = ({ children, uri }) => {
  const client = useApolloClient(uri);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppApolloProvider;
