export const firebaseDevConfig = {
  apiKey: "AIzaSyB_iGIH3tWKMl5psEzYfmZKe7IqkfhpC7Q",
  authDomain: "tma1-products-qa.firebaseapp.com",
  databaseURL: "https://tma1-products-qa.firebaseio.com",
  projectId: "taxscribe-dev",
  storageBucket: "tma1-products-qa.appspot.com",
  messagingSenderId: "905292205669",
  appId: "1:905292205669:web:a6431322ba76cc54d3008c",
  measurementId: "G-V3YMS8XY0C",
};

export const firebaseQAConfig = {
  apiKey: "AIzaSyBXodPuZT7bj8d3NfDkY6L-Ou0o7AqXmJU",
  authDomain: "qa-sso.gov.taxscribe.app",
  projectId: "taxscribe-gov-qa",
  storageBucket: "taxscribe-gov-qa.appspot.com",
  messagingSenderId: "852676336603",
  appId: "1:852676336603:web:9763d49283bcefcff4e5fe",
  measurementId: "G-FWTBBX2PRX",
};

export const firebaseUATConfig = {
  apiKey: "AIzaSyBoo7Cbp7VfeQmK9Lwb_XJGs70zJkn3W6M",
  authDomain: "uat-sso.gov.taxscribe.app",
  projectId: "taxscribe-gov-uat",
  storageBucket: "taxscribe-gov-uat.appspot.com",
  messagingSenderId: "592068758086",
  appId: "1:592068758086:web:10330db9bfeb80cc8ad847",
  measurementId: "G-B09PE93S3T",
};

export const firebaseProdConfig = {
  apiKey: "AIzaSyDsHVWh0ON7gYlBRFbxQJj9vGxfqCYANTE",
  authDomain: "sso.gov.taxscribe.app",
  projectId: "taxscribe-gov-production",
  storageBucket: "taxscribe-gov-production.appspot.com",
  messagingSenderId: "469188296144",
  appId: "1:469188296144:web:c3d908ceb4af6343db8dd5",
  measurementId: "G-HFXD9B6HY5",
};
