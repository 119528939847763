import React, { FC, PropsWithChildren } from "react";

import { Container, Row, Col } from "react-bootstrap";
import logo from "../../../images/rc-logo-gov.png";

const NoAuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container
      fluid
      className="ps-md-0"
    >
      <Row className="g-0 vh-100">
        <Col
          md={4}
          className="d-none d-md-flex justify-content-center ts-gov-bg-teal"
        >
          <div className="d-flex align-items-center text-center">
            <img
              alt="county-logo"
              src={logo}
              width="250"
              height="auto"
            />
          </div>
        </Col>
        <Col md={8}>{children}</Col>
      </Row>
    </Container>
  );
};

export default NoAuthWrapper;
