import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { useAuth } from "@reasongcp/react-fire-sub";
import { Container, Row, Col } from "react-bootstrap";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import EmailLogin from "./EmailLogin";
import ProviderOption from "./ProviderOption";
import useBaseUrl from "../../../hooks/useBaseUrl";
import NoAuthWrapper from "../../shared/NoAuthWrapper";
import useGetAuthProviders from "../../../hooks/useGetAuthProviders";

const ButtonsWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

const useHasJurInfo = () => {
  const [searchParams] = useSearchParams();
  const { stateAbbr, jurisdictionType, jurisdictionSlug } = useParams();
  const hasPathInfo = Boolean(
    stateAbbr || jurisdictionType || jurisdictionSlug,
  );
  const hasQueryInfo = Boolean(searchParams.get("stateAbbr"));
  return hasPathInfo || hasQueryInfo;
};

const Login: FC = () => {
  const user = useAuth();
  const baseUrl = useBaseUrl();
  const hasJurInfo = useHasJurInfo();
  const { data, called } = useGetAuthProviders();

  const includeEmail = useMemo(() => {
    const noJurisdiction = !hasJurInfo;
    const noConfiguredProviders = called && !data?.jurisdiction?.authProviders;
    const explicitEmailProvider = data?.jurisdiction?.authProviders?.some(
      (pro) => {
        return pro.providerType === "EmailAuthProvider";
      },
    );

    return Boolean(
      noJurisdiction || noConfiguredProviders || explicitEmailProvider,
    );
  }, [data, called, hasJurInfo]);

  if (user) {
    return <Navigate to={baseUrl} />;
  }

  return (
    <NoAuthWrapper>
      <div className="d-flex align-items-center vh-100">
        <Container>
          <Row className="align-items-center">
            <Col
              md={8}
              lg={6}
              className="mx-auto"
            >
              <h1 className="login-heading mb-4 h2 fw-light">
                TaxScribe Government
              </h1>
              {includeEmail ? <EmailLogin /> : null}
            </Col>
          </Row>
          {(data?.jurisdiction?.authProviders || []).map((pro) => {
            return (
              <Row className="align-items-center">
                <Col
                  md={8}
                  lg={6}
                  className="mx-auto"
                >
                  <ButtonsWrapper>
                    <ProviderOption
                      key={pro.name}
                      details={pro}
                    />
                  </ButtonsWrapper>
                </Col>
              </Row>
            );
          })}
        </Container>
      </div>
    </NoAuthWrapper>
  );
};

export default Login;
