import { gql, useLazyQuery } from "@apollo/client";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import config from "../config";
import useBaseUrl from "./useBaseUrl";

const GET_JUR_BY_UID = gql`
  query Jurisdiction($uid: String) {
    jurisdiction(uid: $uid) {
      id
      name
      jurisdictionType
      stateAbbr
    }
  }
`;

// SO Credit: https://stackoverflow.com/a/6969486
const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const usePreferJurRoutes = () => {
  const client = useApolloClientWithAuth(config.taxbaseApi);
  const { stateAbbr, jurisdictionType, jurisdictionSlug } = useParams();
  const userDoc = useUserDoc();
  const baseUrl = useBaseUrl();
  const navigate = useNavigate();
  const [query, { called, loading, data }] = useLazyQuery(GET_JUR_BY_UID, {
    client,
  });

  useEffect(() => {
    if (!userDoc?.currentOrganization) return;
    query({ variables: { uid: userDoc.currentOrganization } });
  }, [query, userDoc, baseUrl]);

  useEffect(() => {
    if (!called || loading || !data) return;
    const {
      name,
      stateAbbr: state,
      jurisdictionType: jurType,
    } = data.jurisdiction;

    const base = `/${state}/${jurType}/${name}`
      .toLowerCase()
      .replace(/ /g, "-");
    if (!baseUrl.match(escapeRegex(base))) {
      navigate(`${base}${window.location.pathname}`);
    }
  }, [
    data,
    called,
    baseUrl,
    loading,
    navigate,
    stateAbbr,
    jurisdictionType,
    jurisdictionSlug,
  ]);
};

export default usePreferJurRoutes;
