import { FC, useState, ChangeEvent, useCallback } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { sendPasswordResetEmail } from "firebase/auth";
import { useSUBContext } from "@reasongcp/react-fire-sub";

import config from "../../../config";
import { useAppToastContext } from "@taxscribe/ui";
import NoAuthWrapper from "../../shared/NoAuthWrapper";

const ForgotPassword: FC = () => {
  const { auth } = useSUBContext();
  const [email, setEmail] = useState("");
  const { addToastMessage } = useAppToastContext();

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [setEmail],
  );

  const handleRecovery = async () => {
    if (!email || email.length === 0) {
      addToastMessage({
        type: "warning",
        header: "Email empty",
        body: "Please enter your email",
      });
      return;
    }
    try {
      const urlRedirection = `${config.ssoUrl}/login`;
      await sendPasswordResetEmail(auth!, email, { url: urlRedirection });
      addToastMessage({
        type: "info",
        header: "Successful",
        body: "Email for password reset sent!",
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <NoAuthWrapper>
      <div className="d-flex align-items-center vh-100">
        <Container>
          <Row className="align-items-center">
            <Col
              md={8}
              lg={6}
              className="mx-auto"
            >
              <h1 className="login-heading mb-4 h2 fw-light">
                Forgot Password
              </h1>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="Enter Email"
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Button
                type="submit"
                onClick={handleRecovery}
                className="btn btn-dark w-100"
              >
                Send recovery email
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </NoAuthWrapper>
  );
};

export default ForgotPassword;
