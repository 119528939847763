import { FC, PropsWithChildren, useMemo } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import {
  SSOAuthBoundary,
  useAuth,
  useCurrentOrgMembers,
} from "@reasongcp/react-fire-sub";

// import config from "../../../config";
import {
  GovSidebar,
  SidebarMenuItem,
  SSOIcon,
  // SidebarNav,
  // SidebarNavProps,
} from "@taxscribe/react-gov";
import Users from "../../pages/Users";
import Welcome from "../../pages/Welcome";
import ForgotPassword from "../../pages/ForgotPassword";
import useOrganization from "../../../hooks/useOrganization";
import { Link } from "react-router-dom";
import Login from "../../pages/Login";
import useBaseUrl from "../../../hooks/useBaseUrl";
import usePreferJurRoutes from "../../../hooks/usePreferJurRoutes";
import config from "../../../config";
// import LinkAuthProvider from "../LinkAuthProvider";

export const pages: {
  path: string;
  Component: FC;
  title: string;
}[] = [
  {
    Component: Welcome,
    path: "/",
    title: "Welcome To Gov SSO!",
  },
  {
    Component: Users,
    path: "/users",
    title: "Manage Users",
  },
  {
    Component: Users,
    path: "/users/:uid",
    title: "Mange User",
  },
];

const NoAuthNoMatch: FC = () => {
  const [searchParams] = useSearchParams();
  const baseUrl = useBaseUrl();
  return <Navigate to={`${baseUrl}login?${searchParams}`} />;
};

const NoAuthRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/forgotPassword"
        element={<ForgotPassword />}
      />
      <Route
        path="*"
        element={<NoAuthNoMatch />}
      />
    </Routes>
  );
};

const NoMatch: FC = () => {
  const [searchParams] = useSearchParams();
  const baseUrl = useBaseUrl();
  return <Navigate to={`${baseUrl}?${searchParams.toString()}`} />;
};

const AdminOnly: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth();
  const members = useCurrentOrgMembers();
  const isAdmin = useMemo(() => {
    return members
      .find((member) => member.uid === user?.uid)
      ?.roles.some((role: unknown) => role === "admin");
  }, [members, user]);
  if (!isAdmin) return null;

  return <>{children}</>;
};

const appUrls = {
  BPP: config.bppUrl,
  SSO: config.ssoUrl,
};

const GovSSOSidebarNav: FC = () => {
  const organization = useOrganization();
  const appDescription = `GOV SSO ${organization.name || ""} ${organization.stateAbbr || ""}`
    .trim();

  return (
    <GovSidebar
      currentApp="SSO"
      appUrls={appUrls}
      jurisdiction={organization}
      appDescription={appDescription}
    >
      <SidebarMenuItem
        topLevel
        link="/"
        as={Link}
      >
        <SSOIcon />
        SSO
      </SidebarMenuItem>
      <AdminOnly>
        <SidebarMenuItem
          as={Link}
          link="users"
        >
          Users
        </SidebarMenuItem>
      </AdminOnly>
    </GovSidebar>
  );
};

const AppRoutes: FC<{}> = () => {
  const client = useApolloClient();
  usePreferJurRoutes();

  return (
    <SSOAuthBoundary
      gql={gql}
      client={client}
      InvalidPlaceHolder={NoAuthRoutes}
    >
      <GovSSOSidebarNav />
      <div className="app-content">
        <div>
          <Routes>
            {pages.map((page) => (
              <Route
                key={page.path}
                path={page.path}
                element={<page.Component />}
              />
            ))}
            <Route
              path="*"
              element={<NoMatch />}
            />
          </Routes>
        </div>
      </div>
    </SSOAuthBoundary>
  );
};

export default AppRoutes;
