import React, { ChangeEvent, FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Nav, Form } from "react-bootstrap";

import NewUserForm from "./NewUserForm";
import OrganizationUsers from "./OrganizationUsers";
import { useParams } from "react-router";
import User from "./UserDetails";
import { useCurrentOrganization } from "@reasongcp/react-fire-sub";

const UserNav = styled.div`
  @media (min-width: 575px) {
    & > #userbar {
      border-right: #dee2e6 solid 1px;
      min-height: 100vh;
    }
  }
`;

const NewUserContent: FC = () => {
  const { uid } = useParams();
  if (uid !== "new") return null;

  return <NewUserForm />;
};

const NewUserLink: FC = () => {
  const org = useCurrentOrganization();
  const hideAddUsers = org?.preventManualUserCreation;

  if (hideAddUsers) return null;
  return (
    <Nav.Link
      as={Link}
      to="/users/new"
      className="bg-light text-secondary"
    >
      <FontAwesomeIcon
        icon={faPlus}
        aria-hidden="true"
        className="me-2"
      />
      <small>Add User</small>
    </Nav.Link>
  );
};

const Users: FC = () => {
  const [filter, setFilter] = useState("");
  const updateFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilter(e.target.value);
    },
    [setFilter],
  );

  return (
    <Container fluid>
      <Row>
        <Col
          md={3}
          sm={4}
          className="px-0"
        >
          <UserNav>
            <Nav
              id="userbar"
              defaultActiveKey="/home"
              className="flex-column py-3 border-md-end"
            >
              <div className="flex-column mb-3 pe-2">
                <span className="text-uppercase text-muted ls-1 px-3">
                  <small>Users</small>
                </span>
              </div>
              <NewUserLink />
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  className="m-3"
                  onChange={updateFilter}
                  aria-label="Search Users"
                  placeholder="Search Users"
                />
              </Form>
              <OrganizationUsers filter={filter} />
            </Nav>
          </UserNav>
        </Col>
        <Col
          md={9}
          sm={8}
        >
          <NewUserContent />
          <User />
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
