import React, {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  SyntheticEvent,
} from "react";
import { Button, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useAuth, useSignin } from "@reasongcp/react-fire-sub";
import { useAppToastContext } from "@taxscribe/ui";
import { Link } from "react-router-dom";

const EmailLogin: FC = () => {
  const signin = useSignin();
  const user = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { addToastMessage } = useAppToastContext();

  const handleLogin = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      signin(email, password).catch((e: unknown) => {
        console.error(e);
        addToastMessage({
          type: "danger",
          header: "Error logging in",
          body: "Unable to authenticate",
        });
      });
    },
    [email, signin, password, addToastMessage],
  );

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [setEmail],
  );

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [setPassword],
  );

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={email}
          placeholder="Enter Email"
          onChange={handleEmailChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          placeholder="Enter Password"
          onChange={handlePasswordChange}
        />
      </Form.Group>
      <Button
        type="submit"
        onClick={handleLogin}
        className="btn btn-dark w-100"
      >
        Login
      </Button>
      <Link to="/forgotPassword">
        Forgot Password?
      </Link>
    </Form>
  );
};

export default EmailLogin;
