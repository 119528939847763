import { useCallback, useEffect } from "react";
import { getRedirectResult } from "firebase/auth";
import {
  useGetUser,
  useSUBContext,
  useCreateInitialUserDoc,
} from "@reasongcp/react-fire-sub";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import config from "../config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const ADD_AUTHORIZED_USER = gql`
  mutation AddAuthorizedUserToOrganization($orgUid: String!) {
    addAuthorizedUserToOrganization(orgUid: $orgUid)
  }
`;

const GET_JURISDICTION_UID = gql`
  query Jurisdiction(
    $stateAbbr: String
    $jurisdictionName: String
    $jurisdictionType: String
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      id
      uid
    }
  }
`;

interface GetJurisdictionUidResults {
  jurisdiction: {
    id: number;
    uid: string;
  };
}

const useGetJurUidByParams = () => {
  const { stateAbbr, jurisdictionType, jurisdictionSlug } = useParams();
  const client = useApolloClientWithAuth(config.taxbaseApi);
  const [query] = useLazyQuery<GetJurisdictionUidResults>(
    GET_JURISDICTION_UID,
    { client },
  );

  return useCallback(async () => {
    if (!stateAbbr || !jurisdictionSlug || !jurisdictionType) return;
    const variables = {
      stateAbbr,
      jurisdictionType,
      jurisdictionName: jurisdictionSlug?.replace(/-/g, " "),
    };
    const uidLookupResults = await query({ variables });

    return uidLookupResults?.data?.jurisdiction.uid;
  }, [query, stateAbbr, jurisdictionSlug, jurisdictionType]);
};

const useCreateMemberDockIfRequired = () => {
  const [mutation] = useMutation(ADD_AUTHORIZED_USER);
  const getJurUid = useGetJurUidByParams();

  return useCallback(async () => {
    const orgUid = await getJurUid();

    if (!orgUid) {
      throw new Error("No orgUid found in params");
    }
    await mutation({ variables: { orgUid } });
  }, [mutation, getJurUid]);
};

const useSetMyCurrentOrg = () => {
  const { firestore, user } = useSUBContext();
  const getJurUid = useGetJurUidByParams();

  return useCallback(async () => {
    if (!firestore || !user) return;
    const jurUid = await getJurUid();
    const memberDocRef = doc(
      firestore,
      `/organizations/${jurUid}/members`,
      user.uid,
    );
    if (!jurUid) return;
    const memberDoc = await getDoc(memberDocRef);

    if (!memberDoc.exists()) return;

    const userDoc = doc(firestore, "/users", user.uid);
    await updateDoc(userDoc, { currentOrganization: jurUid });
  }, [getJurUid, firestore, user]);
};

/*
 * useNewRedirectedUserDoc checks to see if there are authentication redirect
 * results and if there are checks to see if the user has a document yet or
 * not. If not, it creates one. Otherwise it returns having done nothing.
 */
const useNewRedirectedUserDoc = () => {
  const { auth } = useSUBContext();
  const getUser = useGetUser();
  const createUserDoc = useCreateInitialUserDoc();
  const createMemberDoc = useCreateMemberDockIfRequired();
  const setCurrentOrg = useSetMyCurrentOrg();

  useEffect(() => {
    if (auth) {
      getRedirectResult(auth)
        .then(async (redirectResults) => {
          if (redirectResults) return { user: redirectResults.user };
          return { user: auth.currentUser };
        })
        .then(async (redirectResults) => {
          if (!redirectResults?.user) return;
          const userDoc = await getUser();
          if (!userDoc) {
            await createUserDoc(redirectResults.user);
          }

          await createMemberDoc();
          await setCurrentOrg();
        })
        .catch((err) => {
          console.error("Error creating user doc");
          console.error(err);
        });
    }
  }, [auth, getUser, setCurrentOrg, createUserDoc, createMemberDoc]);
};

export default useNewRedirectedUserDoc;
