import { FC } from "react";
import { useParams } from "react-router";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { useAppToastContext } from "@taxscribe/ui";

import FormikSelect from "../../shared/FormikSelect";
import { RoleList } from "./Roles";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import useOrganization from "../../../hooks/useOrganization";
import FormikErrorHandler from "../../shared/FormikErrorHandler";
import { Member } from "../../../queries/organizations";

const FormButton = styled(Button)`
  width: 220px;
`;

const requiredText = "* Required";

const userSchema = Yup.object().shape({
  displayName: Yup.string().required("Required *"),
  roles: Yup.array().min(1, requiredText).required(requiredText),
});

const FormGroupWrapper = styled(Col)`
  margin-top: 0.5rem;
`;

interface UserDataProps {
  orgUid: string;
  member: Member;
}

const UserData: FC<UserDataProps> = ({ orgUid, member }) => {
  const { addToastMessage } = useAppToastContext();
  const updateUser = useUpdateUser();

  const formik = useFormik({
    initialValues: {
      roles: member.roles,
      displayName: member.displayName,
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const { roles } = values;
        const rolesValue = roles.length ? roles : ["user"];
        await updateUser({
          orgUid,
          userUid: member.uid,
          options: {
            roles: rolesValue,
            displayName: values.displayName,
          },
        });

        addToastMessage({
          type: "success",
          header: "User Updated",
          body: "User updated successfully",
        });
      } catch (e) {
        console.error(e);
        addToastMessage({
          type: "danger",
          header: "Error Updating User",
          body: "Unable to update user",
        });
      }
    },
  });

  return (
    <Container fluid>
      <Row className="border-bottom">
        <Col className="p-3 p-md-5">
          <h1 className="h2 fw-bold">{member.displayName}</h1>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="p-3 p-md-5">
          <Col xs={3}>
            <h4>UID:</h4>
          </Col>
          <Col xs={9}>{member.uid}</Col>
          <Col xs={3}>
            <h4>Last Signed In:</h4>
          </Col>
          <Col xs={6}>{member.lastSignInTime}</Col>
          <Col xs={3} />
          <Col xs={3}>
            <h4>Email:</h4>
          </Col>
          <Col xs={9}>{member.email}</Col>
          <Col xs={3}>
            <h4>Name:</h4>
          </Col>
          <FormGroupWrapper xs={6}>
            <Form.Group>
              <Form.Control
                type="text"
                name="displayName"
                placeholder="Jane Doe"
                onChange={formik.handleChange}
                value={formik.values.displayName}
              />
              <FormikErrorHandler error={formik.errors?.displayName} />
            </Form.Group>
          </FormGroupWrapper>
          <Col xs={3} />
          <Col xs={3}>
            <h4>Roles:</h4>
          </Col>
          <FormGroupWrapper xs={6}>
            <FormikSelect
              formik={formik}
              fieldName="roles"
              options={RoleList}
            />
            <FormikErrorHandler error={formik.errors?.roles} />
          </FormGroupWrapper>
        </Row>
        <Row>
          <Col xs={{ span: 3, offset: 6 }}>
            <FormButton
              variant="dark"
              type="submit"
              className="me-4 px-5 mb-0 mb-lg-0 mb-sm-2 float-end"
            >
              Save
            </FormButton>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const User: FC = () => {
  const { uid } = useParams();
  const organization = useOrganization();
  const members = organization?.members;
  const user = members?.find((user) => user.uid === uid);

  if (!user || !organization) return null;

  return (
    <UserData
      member={user}
      orgUid={organization?.uid || ""}
    />
  );
};

export default User;
