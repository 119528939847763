import React, { FC } from "react";
import {
  AppToastProvider,
  AppToasts,
  EnvBanner,
  ToastComponentProps,
} from "@taxscribe/ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";

import config from "./config";
import AppRoutes from "./components/shared/AppRoutes";
import ApolloProvider from "./components/shared/ApolloProvider";
import Success from "./components/shared/Toasts/Success";
import Error from "./components/shared/Toasts/Error";
import Warning from "./components/shared/Toasts/Warning";
import Danger from "./components/shared/Toasts/Danger";

const toastComponents: Record<string, FC<ToastComponentProps>> = {
  success: Success,
  error: Error,
  warning: Warning,
  danger: Danger,
};

const useFirebaseEmulator = config.releaseStage === "development";

function App() {
  return (
    <div className="App">
      <SUBContextProvider
        emulate={useFirebaseEmulator}
        config={config.firebaseConfig}
        authCookieName={config.authCookieName}
      >
        <ApolloProvider uri={config.fireSubApi}>
          <BrowserRouter>
            <div className="d-flex flex-column">
              <AppToastProvider
                defaultTimeout={5000}
                position="top-end"
                ToastComponents={toastComponents}
              >
                <div className="app-content">
                  <EnvBanner releaseStage={config.releaseStage} />
                </div>
                <Routes>
                  <Route
                    element={<AppRoutes />}
                    path="/:stateAbbr/:jurisdictionType/:jurisdictionSlug/*"
                  />
                  <Route
                    path="*"
                    element={<AppRoutes />}
                  />
                </Routes>
                <AppToasts />
              </AppToastProvider>
            </div>
          </BrowserRouter>
        </ApolloProvider>
      </SUBContextProvider>
    </div>
  );
}

export default App;
