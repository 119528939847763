import { GovConfig, govConfig } from "@taxscribe/react-gov";
import { FirebaseOptions } from "firebase/app";
import { camelCase } from "lodash";

import {
  firebaseDevConfig,
  firebaseProdConfig,
  firebaseUATConfig,
  firebaseQAConfig,
} from "./firebaseConfig";

const hostname = window.location.hostname;
const getCookieName = (): string => {
  const prefix = "auth";
  const domain = hostname.split(".").slice(1).join("_");

  return camelCase(`${prefix}_${domain}`);
};

interface AppConfig extends GovConfig {
  firebaseConfig: FirebaseOptions;
  authCookieName: string;
}

const configs: Record<string, AppConfig> = {
  "127.0.0.1": {
    ...govConfig.development,
    govApi: "http://localhost:3030/",
    firebaseConfig: firebaseDevConfig,
    authCookieName: getCookieName(),
  },
  "localhost": {
    ...govConfig.development,
    govApi: "http://localhost:3030/",
    firebaseConfig: firebaseDevConfig,
    authCookieName: getCookieName(),
  },
  "qa-sso.gov.taxscribe.app": {
    ...govConfig.qa,
    govApi: "https://qa-api.gov.taxscribe.app/",
    firebaseConfig: firebaseQAConfig,
    authCookieName: getCookieName(),
  },
  "uat-sso.gov.taxscribe.app": {
    ...govConfig.uat,
    govApi: "https://uat-api.gov.taxscribe.app/",
    firebaseConfig: firebaseUATConfig,
    authCookieName: getCookieName(),
  },
  "sso.gov.taxscribe.app": {
    ...govConfig.production,
    govApi: "https://api.gov.taxscribe.app/",
    firebaseConfig: firebaseProdConfig,
    authCookieName: getCookieName(),
  },
};

export const getConfig = (currentHost: string) => {
  if (currentHost.match("localhost")) return configs.localhost;
  const config = configs[currentHost];

  if (!config) throw new Error(`No configuration found for ${currentHost}`);
  return config;
};

export default getConfig(hostname);
